/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit'
import normesService, {
  ConflictBar, ConflictsType, GOVMetrics, MetricsNormes, MetricsType, NormesLineChart, ReusabilityBar,
  ReusabilityTable,
} from './services/normesService'
import { ErrorMessage } from './types'

interface NormesState {
  normesList: MetricsNormes[];
  missingDays: {
    'Conception (COTECH GOV)'?: { [key: string]: string }[];
    'Théorique (J-1)'?: { [key: string]: string }[];
    'Réel (J)'?: { [key: string]: string }[];
  };
  missingGare: {
    'Conception (COTECH GOV)': string;
    'Théorique (J-1)': string;
    'Réel (J)': string;
  };
  activeNormes: number;
  conflictBar: ConflictBar;
  normesLineList: NormesLineChart[];
  reusabilityBar: ReusabilityBar;
  reoccupationLineList: NormesLineChart[];
  reoccupationTable: ReusabilityTable;
  conflictsType: ConflictsType[];
  isLoading: boolean;
  selectedPoint: {
    id: string;
    date: string;
  };
  conflictEvolutionMetrics: MetricsType | null;
  reusabilityTable: ReusabilityTable;
  detailTable: ReusabilityTable;
  historyTable: ReusabilityTable;
  isLoadingReutilityBar: boolean;
  isLoadingReutilityTable: boolean;
  isLoadingConflictLine: boolean;
  isLoadingConflictBar: boolean;
  isLoadingReoccupationLine: boolean;
  isLoadingReoccupationTable: boolean;
  errorReutilityBar: ErrorMessage | null;
  errorReutilityTable: ErrorMessage | null;
  errorConflictLine: ErrorMessage | null;
  errorConflictBar: ErrorMessage | null;
  errorReoccupationLine: ErrorMessage | null;
  errorReoccupationTable: ErrorMessage | null;
  activeGov: GOVMetrics;
}

const initialState: NormesState = {
  missingGare: {
    'Conception (COTECH GOV)': '',
    'Théorique (J-1)': '',
    'Réel (J)': '',
  },
  missingDays: {},
  normesList: [],
  activeNormes: 0,
  conflictBar: {
    meta: {
      bar_mode: '',
      info: [],
    },
    data: [],
  },
  normesLineList: [],
  reusabilityBar: {
    meta: {
      bar_mode: '',
      info: [],
    },
    data: [],
  },
  reusabilityTable: {
    meta: {
      index_label: '',
    },
    data: [],
  },
  conflictsType: [],
  reoccupationLineList: [],
  isLoading: true,
  selectedPoint: {
    id: '',
    date: '',
  },
  conflictEvolutionMetrics: null,
  detailTable: {
    meta: {
      index_label: '',
    },
    data: [],
  },
  historyTable: {
    meta: {
      index_label: '',
    },
    data: [],
  },
  reoccupationTable: {
    meta: {
      index_label: '',
    },
    data: [],
  },
  isLoadingReutilityBar: false,
  isLoadingReutilityTable: false,
  isLoadingConflictLine: false,
  isLoadingConflictBar: false,
  isLoadingReoccupationLine: false,
  isLoadingReoccupationTable: false,
  errorReutilityBar: null,
  errorReutilityTable: null,
  errorConflictLine: null,
  errorConflictBar: null,
  errorReoccupationLine: null,
  errorReoccupationTable: null,
  activeGov: GOVMetrics.CONCEPTION,
}

export const normes = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    reset: () => initialState,
    setActiveNormes: (state, action) => {
      state.activeNormes = action.payload
    },
    setSelectedPoint: (state, action) => {
      state.selectedPoint = action.payload
    },
    setActiveGov: (state, action) => {
      state.activeGov = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(normesService.getNormesJsa.fulfilled, (state, action) => {
      state.isLoading = true
      state.normesList = action.payload.metrics
      state.missingDays = action.payload.missing_days
      state.missingGare = action.payload.gare
    })
    builder.addCase(normesService.getNormesJsa.pending, state => {
      state.isLoading = true
    })
    builder.addCase(normesService.getNormesJsa.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(normesService.getMetricsNormes.pending, (state, action) => {
      switch (action.meta.arg.board) {
        case 'Réutilisation': {
          if (action.meta.arg.uri.includes('reutilisation_distribution')) {
            state.isLoadingReutilityBar = true
            state.errorReutilityBar = null
          } else {
            state.isLoadingReutilityTable = true
            state.errorReutilityTable = null
          }
          break
        }
        case 'Conflits GOV': {
          if (action.meta.arg.uri.includes('conflict_distribution')) {
            state.isLoadingConflictBar = true
            state.errorConflictBar = null
          } else {
            state.isLoadingConflictLine = true
            state.errorConflictLine = null
          }
          break
        }
        case 'Réoccupation': {
          if (action.meta.arg.uri.includes('reoccupation_variability')) {
            state.isLoadingReoccupationLine = true
            state.errorReoccupationLine = null
          } else {
            state.isLoadingReoccupationTable = true
            state.errorReoccupationTable = null
          }
          break
        }
        default:
          break
      }
      state.isLoading = true
    })
    builder.addCase(normesService.getMetricsNormes.fulfilled, (state, action) => {
      switch (action.meta.arg.board) {
        case 'Réutilisation': {
          if (action.meta.arg.uri.includes('reutilisation_distribution')) {
            state.reusabilityBar = action.payload
            state.isLoadingReutilityBar = false
          } else {
            state.reusabilityTable = action.payload
            state.isLoadingReutilityTable = false
          }
          break
        }
        case 'Conflits GOV': {
          if (action.meta.arg.uri.includes('conflict_distribution')) {
            state.conflictBar = action.payload
            state.isLoadingConflictBar = false
          } else {
            state.normesLineList = action.payload.data
            state.isLoadingConflictLine = false
          }
          break
        }
        case 'Réoccupation': {
          if (action.meta.arg.uri.includes('reoccupation_variability')) {
            state.reoccupationLineList = action.payload.data
            state.isLoadingReoccupationLine = false
          } else {
            state.reoccupationTable = action.payload
            state.isLoadingReoccupationTable = false
          }
          break
        }
        default:
          break
      }
      state.isLoading = false
    })
    builder.addCase(normesService.getMetricsNormes.rejected, (state, action) => {
      switch (action.meta.arg.board) {
        case 'Réutilisation': {
          if (action.meta.arg.uri.includes('reutilisation_distribution')) {
            state.errorReutilityBar = action.payload as ErrorMessage
            state.isLoadingReutilityBar = false
          } else {
            state.errorReutilityTable = action.payload as ErrorMessage
            state.isLoadingReutilityTable = false
          }
          break
        }
        case 'Conflits GOV': {
          if (action.meta.arg.uri.includes('conflict_distribution')) {
            state.errorConflictBar = action.payload as ErrorMessage
            state.isLoadingConflictBar = false
          } else {
            state.errorConflictLine = action.payload as ErrorMessage
            state.isLoadingConflictLine = false
          }
          break
        }
        case 'Réoccupation': {
          if (action.meta.arg.uri.includes('reoccupation_variability')) {
            state.errorReoccupationLine = action.payload as ErrorMessage
            state.isLoadingReoccupationLine = false
          } else {
            state.errorReoccupationTable = action.payload as ErrorMessage
            state.isLoadingReoccupationTable = false
          }
          break
        }
        default:
          break
      }
      state.isLoading = false
    })
    builder.addCase(normesService.getConflictsType.fulfilled, (state, action) => {
      state.conflictsType = action.payload
    })
    builder.addCase(normesService.getMetricsConflictEvolutionList.fulfilled, (state, action) => {
      state.conflictEvolutionMetrics = action.payload
    })
    builder.addCase(normesService.getDetailReusability.pending, state => {
      state.isLoading = true
    })
    builder.addCase(normesService.getDetailReusability.fulfilled, (state, action) => {
      state.detailTable = action.payload
      state.isLoading = false
    })
    builder.addCase(normesService.getDetailReusability.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(normesService.getDetailReusabilityHistory.pending, state => {
      state.isLoading = true
    })
    builder.addCase(normesService.getDetailReusabilityHistory.fulfilled, (state, action) => {
      state.historyTable = action.payload
      state.isLoading = false
    })
    builder.addCase(normesService.getDetailReusabilityHistory.rejected, state => {
      state.isLoading = false
    })
  },
})

export const {
  reset, setActiveNormes, setSelectedPoint, setActiveGov,
} = normes.actions

export default normes.reducer
